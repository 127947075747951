

button *:not(a), a * {
    pointer-events: none;
}

// button, .clickable {
//     & p, & span {
//         user-select: none;
//     }
// }

// button p, a p {
//     white-space: nowrap;
// }

a.block:not(.row) {
    display: inline-block;
}

button, a, .label {
    transition: background-color 0.2s ease-in-out;

    & * {
        transition: color 0.2s ease-in-out,
                    opacity 0.2s ease-in-out;
    }

    & > p {
        letter-spacing: -0.01em;
    }

    &.btn-google {
        transition: background-color .218s, border-color .218s, box-shadow .218s;
        &.desktop {
            padding: 0px 17.4px;
            border-radius: 5.8px;
            background: #F2F2F2;
            gap: 14.5px;
            height: 58px;

            & > img {
                width: 29px;
                height: 29px;
            }

            & > p {
                color: #1F1F1F;
                font-family: 'Roboto', Arial, sans-serif;
                font-size: 20.3px;
                font-style: normal;
                font-weight: 500;
                line-height: 29px;
                letter-spacing: 0.406px;
            }
        }
        
        &.mobile {
            padding: 0px 14px;
            border-radius: 4.811px;
            background: #F2F2F2;
            gap: 12px;
            height: 48px;

            & > img {
                width: 24px;
                height: 24px;
            }

            & > p {
                color: #1F1F1F;
                font-family: 'Roboto', Arial, sans-serif;
                font-size: 16.8px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px; 
                letter-spacing: 0.336px;
            }
        }
    }

    &.btn-block-new {
        &.desktop {
            padding: 14px;
            &.p-3 {
                padding: 24px;
            }
            &:not(.b, .b-1, .b-2) {
                border-radius: 24px;
            }
            & > p:not([data-size]), 
            & > *:not(button) > p:not([data-size]), 
            & > *:not(button) > *:not(button) > p:not([data-size]) {
                font-size: 16px;
                line-height: 19px;
                font-weight: 600;
            }
        }
        &.mobile {
            padding: 14px;
            border-radius: 24px;
            & > p:not([data-size]), 
            & > *:not(button) > p:not([data-size]), 
            & > *:not(button) > *:not(button) > p:not([data-size]) {
                font-size: 16px;
                line-height: 19px;
                font-weight: 600;
            }
        }
    }

    &.btn-circled {
        &-16 {
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 7px;
            padding-right: 7px;
            &.p-left {
                padding-left: 0;
            }
            &.p-left {
                padding-right: 0;
            }            
            border-radius: 10px;
            gap: 4px;

            & > p {
                line-height: 16px;
                white-space: nowrap;
                &:not([data-size]) {
                    line-height: 16px;
                    font-size: 8px;
                }
                &:not(.regular) {
                    font-weight: 600;
                }
            }
            
            &[data-border] > p {
                line-height: 14px;
                &:not([data-size]) {
                    line-height: 14px;
                }
            }
        }
        &-24 {
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 8px;
            padding-right: 8px;
            &.p-left {
                padding-left: 0;
            }
            &.p-left {
                padding-right: 0;
            }            
            border-radius: 16px;
            gap: 4px;

            & > p {
                line-height: 24px;
                white-space: nowrap;
                &:not([data-size]) {
                    line-height: 14px;
                    padding-top: 5px;
                    padding-bottom: 5px;
                    font-size: 12px;
                }
                &:not(.regular) {
                    font-weight: 600;
                }
            }
            
            &[data-border] > p {
                line-height: 22px;
                &:not([data-size]) {
                    line-height: 14px;
                    padding-top: 4px;
                    padding-bottom: 4px;
                }
            }
        }
        &-32 {
            padding-top: 4px;
            padding-bottom: 4px;
            padding-left: 14px;
            padding-right: 14px;
            &.p-left {
                padding-left: 4px;
            } 
            &.p-right {
                padding-right: 4px;
            } 
            &.p-both {
                padding-left: 8px;
                padding-right: 8px;
            }
            border-radius: 20px;
            gap: 4px;

            & > p {
                line-height: 24px;
                white-space: nowrap;
                &:not([data-size]) {
                    line-height: 18px;
                    padding-top: 2px;
                    padding-bottom: 4px;
                    font-size: 14px;
                }
                &:not(.regular) {
                    font-weight: 600;
                }
            }
            
            &[data-border] {
                padding-left: 13px;
                padding-right: 13px;
                & > p {
                    line-height: 22px;
                    &:not([data-size]) {
                        line-height: 18px;
                        padding-top: 1px;
                        padding-bottom: 3px;
                    }
                }
            }
        }
        &-40 {
            padding-top: 8px;
            padding-bottom: 8px;
            padding-left: 24px;
            padding-right: 24px;
            &.p-left {
                padding-left: 8px;
                &:not(.p-right) {
                    padding-right: 14px;
                }
            } 
            &.p-right {
                padding-right: 8px;
                &:not(.p-left) {
                    padding-left: 14px;
                }
            } 
            border-radius: 25px;
            gap: 4px;

            & > p {
                line-height: 24px;
                white-space: nowrap;
                &:not([data-size]) {
                    line-height: 19px;
                    padding-top: 2px;
                    padding-bottom: 3px;
                    font-size: 16px;
                }
                &:not(.regular) {
                    font-weight: 600;
                }
            }
            
            &[data-border] {
                padding-left: 23px;
                padding-right: 23px;
                & > p {
                    line-height: 22px;
                    &:not([data-size]) {
                        line-height: 19px;
                        padding-top: 1px;
                        padding-bottom: 2px;
                    }
                }
            }
        }

        &-32.mobile > p:not([data-size]) {
            padding-top: 5px;
            padding-bottom: 5px;
            line-height: 14px;
            font-size: 12px;
        }
    }

    &.btn-rectangle {
        &-24 {
            padding-top: 0;
            padding-bottom: 0;
            padding-left: 14px;
            padding-right: 14px;
            &.p-left {
                padding-left: 0;
            }
            &.p-left {
                padding-right: 0;
            }            
            border-radius: 4px;
            gap: 4px;

            & > p {
                line-height: 24px;
                white-space: nowrap;
                &:not([data-size]) {
                    line-height: 14px;
                    padding-top: 4px;
                    padding-bottom: 6px;
                    font-size: 12px;
                }
                &:not(.regular) {
                    font-weight: 600;
                }
            }
        }
        &-32 {
            padding-top: 4px;
            padding-bottom: 4px;
            padding-left: 14px;
            padding-right: 14px;
            &.p-left {
                padding-left: 4px;
            } 
            &.p-right {
                padding-right: 4px;
            } 
            &.p-both {
                padding-left: 8px;
                padding-right: 8px;
            }
            border-radius: 8px;
            gap: 4px;

            & > p {
                line-height: 24px;
                white-space: nowrap;
                &:not([data-size]) {
                    line-height: 18px;
                    padding-top: 2px;
                    padding-bottom: 4px;
                    font-size: 14px;
                }
                &:not(.regular) {
                    font-weight: 600;
                }
            }
        }
        &-40 {
            padding-top: 8px;
            padding-bottom: 8px;
            padding-left: 24px;
            padding-right: 24px;
            &.p-left {
                padding-left: 8px;
                &:not(.p-right) {
                    padding-right: 14px;
                }
            } 
            &.p-right {
                padding-right: 8px;
                &:not(.p-left) {
                    padding-left: 14px;
                }
            } 
            border-radius: 8px;
            gap: 4px;

            & > p {
                line-height: 24px;
                white-space: nowrap;
                &:not([data-size]) {
                    line-height: 19px;
                    padding-top: 2px;
                    padding-bottom: 3px;
                    font-size: 16px;
                }
                &:not(.regular) {
                    font-weight: 600;
                }
            }
        }

        &-32.mobile > p:not([data-size]) {
            padding-top: 5px;
            padding-bottom: 5px;
            line-height: 14px;
            font-size: 12px;
        }
    }

    &.btn-tab-new {
        &.desktop,
        &.mobile {
            padding-top: 8px;
            padding-bottom: 8px;
            padding-left: 14px;
            padding-right: 14px;
            &.p-left {
                padding-left: 8px;
            }
            &.p-right {
                padding-right: 8px;
            }            
            border-radius: 25px;
            gap: 8px;

            & > img, & > svg, & > .block[data-color] {
                &:first-child {
                    margin-left: -6px;
                }
                &:last-child {
                    margin-right: -6px;
                }
            }
        }
        &.desktop {
            & > p {
                line-height: 24px;
                white-space: nowrap;
                &:not([data-size]) {
                    line-height: 19px;
                    padding-top: 2px;
                    padding-bottom: 3px;
                    font-size: 16px;
                }
                &:not(.regular) {
                    font-weight: 600;
                }
            }
        }
        &.mobile {
            & > p {
                line-height: 24px;
                white-space: nowrap;
                &:not([data-size]) {
                    line-height: 19px;
                    padding-top: 2px;
                    padding-bottom: 3px;
                    font-size: 16px;
                }
                &:not(.regular) {
                    font-weight: 600;
                }
            }
        }
    }    

    &.btn-flex {
        gap: 8px; 
        & > p {                
            white-space: nowrap;
            line-height: 24px;
            &:not(.regular) {
                font-weight: 600;
            }
        } 
        &.mobile > p:not([data-size]) {
            font-size: 16px;
        }
        &.mobile.block:not(.custom-padding) {
            padding: 12px 14px;
            height: 48px;
            border-radius: 8px; 
            &.circled {
                border-radius: 50px;
            }         
        } 
        &.desktop > p:not([data-size]) {
            font-size: 16px;
        }
        &.desktop.block:not(.custom-padding) {
            padding: 12px 14px;
            height: 48px;
            border-radius: 8px; 
            &.circled {
                padding: 12px 22px;
                &.p-right {
                    padding-right: 16px;
                }
                &:not(.not-high) {
                    padding: 17px 22px;
                    height: 58px;
                    &.p-right {
                        padding-right: 16px;
                    }
                }
                border-radius: 50px;
            }
            &.high {
                padding: 17px 14px;
                height: 58px;
            }          
        }
    }
}

button:hover:not(:disabled):not(:active):not(.mobile), 
a:hover:not(:disabled):not(:active):not(.mobile) {
    &.btn-google {
        background-color: #eaecee;
        -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
        box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
    }

    &.btn {               
        &.block[data-color='white'],
        &.block:not([data-color]) {
            background-color: $dark-white;
            & > p:not(.not-active) {
                opacity: 0.95;
            }
        }
        &[data-color='black'] {
            background-color: $dark-gray;
        }
        &[data-color='light-gray'] {
            background-color: $hover-gray;
        }
        &.hover-gray {
            background-color: $light-gray;
        }
        &.hover-dark-white {
            background-color: $dark-white;
        }
    }

    &.btn-block-new {
        &.block[data-color='white'],
        &.block:not([data-color]) {
            background-color: $dark-white;
        }
        &[data-color='black'] {
            background-color: $dark-gray;
        }
        &[data-color='light-gray'] {
            background-color: $hover-gray;
        }

        &.block:not([data-color='white']),
        &.block:not([data-color='black']),
        &.block:not([data-color='light-gray']) {
            opacity: 0.95;
        }
    }

    &.btn-circled-16,
    &.btn-circled-24,
    &.btn-circled-32,
    &.btn-circled-40,
    &.btn-rectangle-24,
    &.btn-rectangle-32,
    &.btn-rectangle-40,
    &.btn-tab-new,
    &.btn-flex {        
        &.block[data-color='white'],
        &.block:not([data-color]) {
            // background-color: $dark-white;
            background-color: $gray;
            &[data-border] {
                opacity: 0.9;
            }
        }
        &[data-color='black'] {
            background-color: $dark-gray;
        }
        &[data-color='light-gray'] {
            background-color: $hover-gray;
        }

        &[data-color='transparent'],
        &[data-color='tp-white'],
        &[data-color='tp-black'],
        &:not(.block) {
            & > p:not(.not-active) {
                opacity: 0.9;
            }
        }

        &[data-border] {
            opacity: 0.9;
        }
    }
}

button:active,
.clickable:active,
a:active {
    -webkit-tap-highlight-color: transparent;
    outline: none;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
}

button:active:not(:disabled), 
a:active:not(:disabled) {
    &.btn-google {
        background-color: #eaecee;
        -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
        box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
    }

    &.btn {
        &.block[data-color='white'],
        &.block:not([data-color]) {
            // background-color: $dark-white;
            background-color: $gray;
        }
        &[data-color='black'] {
            background-color: $dark-gray;
        }
        &[data-color='light-gray'] {
            background-color: $hover-gray;
        }

        & svg, & .btn-symbol, & p, & img, & .custom-hover, & .block[data-color] {
            &:not(.not-active) {
                opacity: 0.4;
            }
            & * {
                opacity: 1;
            }
        }
    }

    &.btn-block-new {
        &.block[data-color='white'],
        &.block:not([data-color]) {
            background-color: $dark-white;
        }
        &[data-color='black'] {
            background-color: $dark-gray;
        }
        &[data-color='light-gray'] {
            background-color: $hover-gray;
        }

        &.block:not([data-color='white']),
        &.block:not([data-color='black']),
        &.block:not([data-color='light-gray']) {
            opacity: 0.95;
        }

        & svg, & .btn-symbol, & p, & img, & .custom-hover, & .block[data-color] {
            &:not(.not-active) {
                opacity: 0.4;
            }
            & svg, & img {
                opacity: 1;
            }
        }
    }

    &.btn-circled-16,
    &.btn-circled-24,
    &.btn-circled-32,
    &.btn-circled-40,
    &.btn-rectangle-24,
    &.btn-rectangle-32,
    &.btn-rectangle-40,
    &.btn-tab-new,
    &.btn-flex {
        &.block[data-color='white'],
        &.block:not([data-color]) {
            // background-color: $dark-white;
            background-color: $gray;
            &[data-border] {
                background-color: $white;
                opacity: 0.4;
            }
        }
        &[data-color='black'] {
            background-color: $dark-gray;
        }
        &[data-color='light-gray'] {
            background-color: $hover-gray;
        }

        &[data-border] {
            opacity: 0.4;
        }

        & svg, & .btn-symbol, & p, & img, & .custom-hover, & .block[data-color] {
            &:not(.not-active) {
                opacity: 0.4;
            }
            & svg, & img {
                opacity: 1;
            }
        }
    }
}

button:disabled, a:disabled {
    &.btn-block-new {
        &.desktop {
            background-color: $gray;

            & p {
                color: $white;
            }

            & .block[data-color] {
                background-color: $black-10-opacity;
            }

            & .btn-symbol, & img, & .custom-hover, & svg {
                opacity: 0.1;
                & * {
                    opacity: 1;
                }
            }
        }
    }

    &.btn {
        & .custom-hover,
        & img:not(.not-active),
        & svg:not(.not-active),
        & p:not(.not-active) {
            opacity: 0.4;
        }
    }

    &.btn-circled-16,
    &.btn-circled-24,
    &.btn-circled-32,
    &.btn-circled-40,
    &.btn-rectangle-24,
    &.btn-rectangle-32,
    &.btn-rectangle-40,
    &.btn-tab-new,
    &.btn-flex {
        &.block:not([data-color='transparent']) {
            background-color: $gray;

            & p {
                color: $white;
            }
        }
        &[data-color='transparent'] {
            & p:not(.not-active),
            & img:not(.not-active),  
            & svg:not(.not-active),
            & .btn-symbol:not(.not-active), 
            & .custom-hover  {
                opacity: 0.4;
                & * {
                    opacity: 1;
                }
            }
        }
        &[data-bg-color][data-color='black'] {
            background-color: $black-10-opacity;

            &[data-bg-color='light-blue'] p {
                color: $light-blue;
            }
            &[data-bg-color='light-yellow'] p {
                color: $light-yellow;
            }
            &[data-bg-color='light-pink'] p {
                color: $light-pink;
            }
        }
        & .block[data-color] {
            background-color: $black-10-opacity;
        }
    }
}


// ===================


button, a, .label {
    &.btn-block {
        &.mobile {
            padding: 24px;
            border-radius: 24px;
            & > p:not([data-size]), 
            & > *:not(button) > p:not([data-size]) {
                font-size: 16px;
                line-height: 22px;                
                &:not(.regular) {
                    font-weight: 600;
                }
            }
        }
        &.desktop {
            padding: 24px;
            border-radius: 24px;
            & > p:not([data-size]), 
            & > *:not(button) > p:not([data-size]), 
            & > *:not(button) > *:not(button) > p:not([data-size]) {
                font-size: 16px;
                line-height: 22px;
                font-weight: 600;
            }
        }
    }

    &.btn-google {
        transition: background-color .218s, border-color .218s, box-shadow .218s;
        &.desktop {
            padding: 0px 17.4px;
            border-radius: 5.8px;
            background: #F2F2F2;
            gap: 14.5px;
            height: 58px;

            & > img {
                width: 29px;
                height: 29px;
            }

            & > p {
                color: #1F1F1F;
                font-family: 'Roboto', Arial, sans-serif;
                font-size: 20.3px;
                font-style: normal;
                font-weight: 500;
                line-height: 29px;
                letter-spacing: 0.406px;
            }
        }
        
        &.mobile {
            padding: 0px 14px;
            border-radius: 4.811px;
            background: #F2F2F2;
            gap: 12px;
            height: 48px;

            & > img {
                width: 24px;
                height: 24px;
            }

            & > p {
                color: #1F1F1F;
                font-family: 'Roboto', Arial, sans-serif;
                font-size: 16.8px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px; 
                letter-spacing: 0.336px;
            }
        }
    }

    &.btn-checkbox:not(.custom-style) {
        border-radius: 8px;

        &.mobile {
            padding: 12px 14px;
            & > p {
                font-size: 14px;
                line-height: 24px;
            }
        }
        &.desktop {
            padding: 17px 14px;
            & > p {
                font-size: 18px;
                line-height: 24px;
            }
        }
        &[data-selected='true'] {
            background-color: $light-gray;
            & > p {
                color: $black;
            }
        }
        &:not([data-selected='true']) {
            background-color: transparent;
            & > p {
                color: $black;
                opacity: 0.4;
            }
        }
    }

    &.btn-large {
        gap: 8px;
        padding: 0 17px;
        height: 58px;
        border-radius: 30px;

        & > p:not([data-size]) {
            font-size: 24px;
        }
    }

    &.btn-tab {
        & > p {                
            white-space: nowrap;
            line-height: 24px;
            &:not(.regular) {
                font-weight: 600;
            }
        }
        &.mobile > p:not([data-size]) {
            font-size: 16px;
        }
        &.mobile:not(.custom-padding):not(.btn-flex) {
            padding: 8px 14px 8px 14px;
        }
        &.desktop > p:not([data-size]) {
            font-size: 18px;
        }
        &.desktop:not(.custom-padding):not(.btn-flex) {
            padding: 8px 14px 8px 14px;
        }
    }

    &.btn-color, &.btn-chat {
        & > p {                
            white-space: nowrap;
            line-height: 24px;
            &:not(.regular) {
                font-weight: 600;
            }
        }
        &.mobile {
            & p:not([data-size]) {
                font-size: 16px;
            }
        }
        &.mobile.block:not(.custom-padding):not(.btn-flex) {
            padding: 8px 14px 8px 14px;
        }
        &.desktop {
            & p:not([data-size]) {
                font-size: 16px;
            }
        }
        &.desktop.block:not(.custom-padding):not(.btn-flex) {
            padding: 8px 24px 8px 24px;
        }
    }

    &.btn-small {
        & > p {                
            white-space: nowrap;
            line-height: 24px;
            &:not(.regular) {
                font-weight: 600;
            }
        }
        &.mobile {
            & p:not([data-size]) {
                font-size: 14px;
            }
        }
        &.mobile.block:not(.custom-padding):not(.btn-flex) {
            padding: 0px 8px 0px 8px;
        }
        &.desktop {
            & p:not([data-size]) {
                font-size: 14px;
            }
        }
        &.desktop.block:not(.custom-padding):not(.btn-flex) {
            padding: 0px 8px 0px 8px;
        }
    }

    &.btn-chat {
        backdrop-filter: blur(10px);
        background-color: $black-5-opacity;
    }

    &.btn-double {
        & > p {                
            white-space: nowrap;
            line-height: 24px;            
            &:not(.regular) {
                font-weight: 600;
            }
        }
        &.mobile {
            gap: 4px;
            & > p:not([data-size]) {
                font-size: 16px;
            }
        }
        &.mobile.block:not(.custom-padding):not(.btn-flex) {
            &:not(.btn-right) {
                padding: 4px 8px 4px 4px;
            }
            &.btn-right {
                padding: 4px 4px 4px 8px;
            }
        }
        &.desktop {
            gap: 8px;
            & > p:not([data-size]) {
                font-size: 16px;
            }
        }
        &.desktop.block:not(.custom-padding):not(.btn-flex) {
            &:not(.btn-right) {
                padding: 8px 14px 8px 8px;
            }
            &.btn-right {
                padding: 8px 8px 8px 14px;
            }
        }
    }

    &.btn-menu {
        border-radius: 22px;

        & > p {
            font-size: 14px;
            font-weight: 400;
        }

        &[data-disabled='true'] {
            & svg, & .btn-symbol, & img, & .custom-hover {
                &:not(.not-active) {
                    opacity: 0.4;
                }
                & svg, & img {
                    opacity: 1;
                }
            }
        } 
    }
}

button:hover:not(:disabled):not(:active):not(.mobile), a:hover:not(:disabled):not(:active):not(.mobile) {
    &.btn-google {
        background-color: #eaecee;
        -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
        box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
    }

    &.btn-checkbox {
        &:not([data-selected='true']) {
            & p:not(.not-active), & .checkbox-radio {
                opacity: 1;
            }
        }
        
        &[data-selected='true'] p:not(.not-active) {
            opacity: 0.4;
        }
    }

    &.btn-close {
        background-color: #00000006;
        &-white {
            background-color: #FFFFFF06;
        }
    }

    &.btn-double, &.btn-large, &.btn-block, &.btn-small, &.btn-menu {
        & svg, & .btn-symbol, & img, & .custom-hover {
            &:not(.not-active) {
                opacity: 0.4;
            }
            & svg, & img {
                opacity: 1;
            }
        }
    }

    &.btn-color:not([data-color='black']) {
        & svg, & .btn-symbol, & img , & p, & .custom-hover {
            &:not(.not-active) {
                opacity: 0.4;
            }
            & svg, & img {
                opacity: 1;
            }
        }
    }

    &.btn-tab {
        & svg, & .btn-symbol, & img, & p, & .custom-hover {
            &:not(.not-active) {
                opacity: 0.4;
            }
            & svg, & img {
                opacity: 1;
            }
        }
    }

    &.btn-chat {
        background-color: $black-10-opacity;
    }
}

button:active:not(:disabled), a:active:not(:disabled) {
    transition-duration: 0s;

    & * {
        transition-duration: 0s;
    }

    &.btn-google {
        background-color: #eaecee;
        -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
        box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
    }

    &.btn-close {
        &-white {
            background-color: #FFFFFF06;
            & .btn-symbol {
                opacity: 0.4;
                & svg, & img {
                    opacity: 1;
                }
            }
        }
        background-color: #00000006;
        & .btn-symbol {
            &:not(.not-active) {
                opacity: 0.4;
            }
            & svg, & img {
                opacity: 1;
            }
        }
    }

    &.btn-double, &.btn-large, &.btn-block, &.btn-small, &.btn-menu {
        & svg, & .btn-symbol, & img, & p:not(.not-active), & .custom-hover {
            &:not(.not-active) {
                opacity: 0.4;
            }
            & svg, & img {
                opacity: 1;
            }
        }
    }

    &.btn-color, &.btn-small {
        &:not(.mobile):not(.btn-border-black):not(.btn-border-white):not([data-color='black']):not([data-color='transparent']):not(.btn-transparent) {
            background-color: $light-gray;
            & svg, & .btn-symbol, & img , & p:not(.not-active), & .custom-hover {
                opacity: 1;
                color: $black;
            }
        }
    }

    &.btn-color, &.btn-small {
        &.desktop, &.mobile, &[data-color='black'] {
            & svg, & .btn-symbol, & img , & p, & .custom-hover {
                &:not(.not-active) {
                    opacity: 0.4;
                }
                & svg, & img {
                    opacity: 1;
                }
            }
        }
    }

    &.btn-border {
        &-black {
            border-color: #00000066;
            & svg, & .btn-symbol, & img , & p:not(.not-active), & .custom-hover {
                opacity: 0.4;
            }
        }
        &-white {
            border-color: #FFFFFF66;
            & svg, & .btn-symbol, & img , & p, & .custom-hover {
                &:not(.not-active) {
                    opacity: 0.4;
                }
            }
        }
    }

    &.btn-tab {
        & svg, & .btn-symbol, & img, & p:not(.not-active), & .custom-hover {
            &:not(.not-active) {
                opacity: 0.4;
            }
            & svg, & img {
                opacity: 1;
            }
        }
    }
}

button:disabled {
    &.btn-double, &.btn-color, &.btn-tab, &.btn-small, &.btn-menu {
        &:not(.btn-transparent):not([data-color='transparent']) {
            background-color: $gray;
            & p:not(.not-active) {
                color: $white;
            }
        }
        & svg, & .btn-symbol, & img, & .custom-hover, & p:not(.not-active) {
            opacity: 0.4;
            & svg, & img {
                opacity: 1;
            }
        }
        &.btn-transparent {
            & svg, & .btn-symbol, & img, & .custom-hover, & p {
                opacity: 0.1;
            }
        }
    }

    &.btn-block {
        & svg, & .btn-symbol, & img, & p, & .custom-hover {
            &:not(.not-active) {
                opacity: 0.4;
            }
            & svg, & img {
                opacity: 1;
            }
        }
    }
}