.w {  
    &-flex {
        width: 100%;
    }

    &-screen {
        width: 100vw;
    }

    &-flex-m {
        width: calc(100% - 8px);

        &-1 {
            width: calc(100% - 16px);
        }
        &-2 {
            width: calc(100% - 28px);
        }
        &-3 {
            width: calc(100% - 48px);
        }
        &-4 {
            width: calc(100% - 76px);
        }
        &-5 {
            width: calc(100% - 116px);
        }
    }  

    &-flex-p {
        width: calc(100% + 8px);

        &-1 {
            width: calc(100% + 16px);
        }
        &-2 {
            width: calc(100% + 28px);
        }
        &-3 {
            width: calc(100% + 48px);
        }
        &-4 {
            width: calc(100% + 76px);
        }
        &-5 {
            width: calc(100% + 116px);
        }
    } 
}

.h {  
    &-flex {
        height: 100%;
    }  

    &-screen {
        height: 100vh;
    }
}

.max-h {  
    &-flex {
        max-height: 100%;
    }  

    &-screen {
        max-height: 100vh;
    }
}

.min-h {  
    &-flex {
        min-height: 100%;
    }  

    &-screen {
        min-height: 100vh;
    }
}