@import './common/colors.scss';

.chat-input {
    word-break: break-word;
    overflow: hidden;
    position: relative;
                  
    width: calc(100% - 38px);
    overflow: hidden;

    & > p {
        pointer-events: none;
        word-wrap: break-word;
        font-size: 14px;
        line-height: 20px;
        padding: 16px 14px;
        opacity: 0;
    }

    & > textarea {
        position: absolute; 
        top: 0; 
        left: 0; 
        height: 100%;
        overscroll-behavior: none; 
    }

    &.mobile {    
        width: 100%;
        
        max-height: 168px;

        & > p, & > textarea {
            padding: 16px 52px 16px 14px;
            margin-right: -38px;
        }
    }

    &.desktop { 
        max-height: 228px;

        & > p, & > textarea {
            padding: 16px 24px;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
}

.chat-field {
    &.desktop {
        & > div {            
            max-height: 100%;

            overflow-y: auto;
            
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
}

.chat-field-shadow {
    content: '';
    position: relative;
    z-index: 1;
    box-shadow: inset 0 0 20px 0 $black-5-opacity;
    height: 600px;
    margin-bottom: -600px;
    pointer-events: none;
}

.message {
    animation: message-appear 0.3s ease-in-out forwards;
}

.clickable {
    cursor: pointer;
}

@keyframes message-appear {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
