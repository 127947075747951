$green: #0BC582;
$light-green: #E1F4EE;
$bright-green: #B8F1DA;
$dark-green: #089160;
$blue: #1033AF;
$light-blue: #D6DFFF;
$coral: #E25C57;
$extra-light-coral: #FFE5E5;
$yellow: #F4D964;
$light-yellow: #F0E7BE;
$pink: #8944AA;
$light-pink: #E7D9FF;
$bright-blue: #6C8CFF;
$bright-pink: #EA87FB;

$gray: #E8E7E7;
$gray-darker: #CFD0D4;
$light-gray: #F5F5F5;
$dark-gray: #242424;
$hover-gray: #EEEEEE;

$white: #FFFFFF;
$dark-white: #FAFAFA;
$white-5-opacity: #FFFFFF0C;
$white-10-opacity: #FFFFFF1A;
$white-20-opacity: #FFFFFF33;
$white-40-opacity: #FFFFFF66;
$white-50-opacity: #FFFFFF80;
$white-60-opacity: #FFFFFF99;

$black: #000000;
$black-5-opacity: #0000000C;
$black-10-opacity: #0000001A;
$black-20-opacity: #00000033;
$black-40-opacity: #00000066;
$black-50-opacity: #00000080;

$mmco-color: #046cff;