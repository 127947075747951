@use 'sass:math';

@for $i from 1 through 20 {
    $direction: (0.5 - ($i % 2)) * 720;
    @keyframes landing-1-#{$i} {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(calc(#{$direction} * 1deg));
        }
    }
    
    @keyframes sub-landing-1-#{$i} {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(calc(#{$direction} * -1deg));
        }
    }

    .landing-animated-elem-1-#{$i} {
        animation-name: landing-1- + #{$i}; 
        animation-timing-function: linear;
        animation-iteration-count: infinite;

        & > * {
            position: relative;
            top: calc((math.random(200) - 100) * 1px); 
            left: calc((math.random(200) - 100) * 1px);
            animation-name: sub-landing-1- + #{$i}; 
            animation-timing-function: linear;
            animation-iteration-count: infinite;
        }
    }
}

.landing-w-request {
    // width: calc(33.3% - 16px);
    width: calc(50% - 12px);
}

@media (max-width: 1920px) {
    .landing-w-request {
        width: calc(50% - 12px);
    }
}

@media (max-width: 1280px) {
    .landing-w-request {
        width: 100%;
    }
}

.landing-4-imgs {
    width: 1136px;
}

@media (max-width: 1280px) {
    .landing-4-imgs {
        width: 564px;
    }
}
