$sizes_list: 8px, 14px, 24px, 38px, 58px, 94px, 148px, 256px, 418px, 688px;
$ratios_list: 8.33%, 16.66%, 25%, 33.33%, 41.66%, 50%, 58.33%, 66.66%, 75%, 83.33%, 91.67%, 100%;
$size_0: 4px;

$breakpoints: ("lg": 1920px, "md": 1280px, "sm": 1024px, "xs": 768px);

.h {
    height: $size_0;
}
.w {
    width: $size_0;
}
.min-w {
    min-width: $size_0;
}
.max-w {
    max-width: $size_0;
}
.min-w-flex {
    min-width: 100%;
}
.max-w-flex {
    max-width: 100%;
}

.p {
    padding-top: $size_0;
    padding-bottom: $size_0;
    padding-left: $size_0;
    padding-right: $size_0;
}
.m { 
    margin-top: $size_0;
    margin-bottom: $size_0;
    margin-left: $size_0;
    margin-right: $size_0;
}
.neg {
    &-m { 
        margin-top: -$size_0;
        margin-bottom: -$size_0;
        margin-left: -$size_0;
        margin-right: -$size_0;
    }
}
@each $size in $sizes_list {
    $i: index($sizes-list, $size);    
    .p-#{$i} { 
        padding-top: $size;
        padding-bottom: $size;
        padding-left: $size;
        padding-right: $size;
    }
    .m-#{$i} { 
        margin-top: $size;
        margin-bottom: $size;
        margin-left: $size;
        margin-right: $size;
    }
    .neg {
        &-m-#{$i} { 
            margin-top: -$size;
            margin-bottom: -$size;
            margin-left: -$size;
            margin-right: -$size;
        }
    }
}

.px {
    padding-left: $size_0;
    padding-right: $size_0;
}
.py {
    padding-top: $size_0;
    padding-bottom: $size_0;
}
.mx {
    margin-left: $size_0;
    margin-right: $size_0;
}
.my {
    margin-top: $size_0;
    margin-bottom: $size_0;
}.neg {
    &-mx {
        margin-left: -$size_0;
        margin-right: -$size_0;
    }
    &-my {
        margin-top: -$size_0;
        margin-bottom: -$size_0;
    }
}
@each $size in $sizes_list {
    $i: index($sizes-list, $size); 
    .px-#{$i} {
        padding-left: $size;
        padding-right: $size;
    }
    .py-#{$i} {
        padding-top: $size;
        padding-bottom: $size;
    } 
    .mx-#{$i} {
        margin-left: $size;
        margin-right: $size;
    }
    .my-#{$i} {
        margin-top: $size;
        margin-bottom: $size;
    }
    .neg {
        &-mx-#{$i} {
            margin-left: -$size;
            margin-right: -$size;
        }
        &-my-#{$i} {
            margin-top: -$size;
            margin-bottom: -$size;
        }        
    }
}

.pt { 
    padding-top: $size_0;
}
.pb { 
    padding-bottom: $size_0;
}
.pl { 
    padding-left: $size_0;
}
.pr { 
    padding-right: $size_0;
}
.mt { 
    margin-top: $size_0;
}
.mb { 
    margin-bottom: $size_0;
}
.ml { 
    margin-left: $size_0;
}
.mr { 
    margin-right: $size_0;
}
.neg {
    &-mt { 
        margin-top: -$size_0;
    }
    &-mb { 
        margin-bottom: -$size_0;
    }
    &-ml { 
        margin-left: -$size_0;
    }
    &-mr { 
        margin-right: -$size_0;
    }
}

.b { 
    border-radius: $size_0;
}
.bt { 
    border-radius: $size_0 $size_0 0 0;
}
.bb { 
    border-radius: 0 0 $size_0 $size_0;
}
.bl { 
    border-radius: $size_0 0 0 $size_0;
}
.br { 
    border-radius: 0 $size_0 $size_0 0;
}

[data-sticky] {
    position: sticky;
}

.col-0 {
    width: 0;
}

@each $ratio in $ratios_list {
    $i: index($ratios-list, $ratio);
    .col-#{$i} { 
        width: $ratio;
    }

    .flex-#{$i} {
        flex: $i;
    }
}

.h-chat {
    height: 643px;
}

@each $size in $sizes_list {
    $i: index($sizes-list, $size);
    .h-#{$i} { 
        height: $size;
    }
    .w-#{$i} { 
        width: $size;
    }
    .min-w-#{$i} { 
        min-width: $size;
    }
    .max-w-#{$i} { 
        max-width: $size;
    }
    .min-h-#{$i} { 
        min-height: $size;
    }
    .max-h-#{$i} { 
        max-height: $size;
    }

    .pt-#{$i} { 
        padding-top: $size;
    }
    .pb-#{$i} { 
        padding-bottom: $size;
    }
    .pl-#{$i} { 
        padding-left: $size;
    }
    .pr-#{$i} { 
        padding-right: $size;
    }
    .mt-#{$i} { 
        margin-top: $size;
    }
    .mb-#{$i} { 
        margin-bottom: $size;
    }
    .ml-#{$i} { 
        margin-left: $size;
    }
    .mr-#{$i} { 
        margin-right: $size;
    }

    .neg {
        &-mt-#{$i} { 
            margin-top: -$size;
        }
        &-mb-#{$i} { 
            margin-bottom: -$size;
        }
        &-ml-#{$i} { 
            margin-left: -$size;
        }
        &-mr-#{$i} { 
            margin-right: -$size;
        }
    }

    .b-#{$i} { 
        border-radius: $size;
    }
    .bt-#{$i} { 
        border-radius: $size $size 0 0;
    }
    .bb-#{$i} { 
        border-radius: 0 0 $size $size;
    }
    .bl-#{$i} { 
        border-radius: $size 0 0 $size;
    }
    .br-#{$i} { 
        border-radius: 0 $size $size 0;
    }
}

[data-hidden] {
    display: none;
}

.grid {  
    @for $i from 1 through 10 {
        &-#{$i} {         
            display: grid;
            grid-template-columns: repeat(#{$i}, 1fr);
            & > * {
                min-width: 0;
            }

            @for $j from 1 through 10 {
                &-#{$j} {  
                    display: grid;       
                    grid-template-columns: #{$i}fr #{$j}fr;
                    & > * {
                        min-width: 0;
                    }
                }
            }
        }
    }
}

@each $size_name, $breakpoint in $breakpoints {
    [data-#{$size_name}-sticky] {
        position: sticky;
    }

    @media (max-width: $breakpoint) {
        [data-#{$size_name}-sticky='false'] {
            position: unset;
        }
    
        [data-#{$size_name}-hidden] {
            display: none;
        }

        [data-hidden][data-#{$size_name}-shown] {
            display: block;
            &.row, &.column {
                display: flex;
            }
        }

        .#{$size_name}-nowrap {            
            flex-wrap: nowrap;
            
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }
        }

        .#{$size_name}-grid {  
            @for $i from 1 through 10 {
                &-#{$i} {         
                    display: grid;
                    grid-template-columns: repeat(#{$i}, 1fr);
                    & > * {
                        min-width: 0;
                    }

                    @for $j from 1 through 10 {
                        &-#{$j} {   
                            display: grid;      
                            grid-template-columns: #{$i}fr #{$j}fr;
                            & > * {
                                min-width: 0;
                            }
                        }
                    }
                }
            }
        }
    
        .#{$size_name} {
            &-h {
                height: $size_0;
            }
            &-w {
                width: $size_0;
            }
            &-p {
                padding-top: $size_0;
                padding-bottom: $size_0;
                padding-left: $size_0;
                padding-right: $size_0;
            }
            &-px {
                padding-left: $size_0;
                padding-right: $size_0;
            }
            &-py {
                padding-top: $size_0;
                padding-bottom: $size_0;
            }
            &-pt { 
                padding-top: $size_0;
            }
            &-pb { 
                padding-bottom: $size_0;
            }
            &-pl { 
                padding-left: $size_0;
            }
            &-pr { 
                padding-right: $size_0;
            }
            &-m { 
                margin-top: $size_0;
                margin-bottom: $size_0;
                margin-left: $size_0;
                margin-right: $size_0;
            }
            &-neg-m { 
                margin-top: -$size_0;
                margin-bottom: -$size_0;
                margin-left: -$size_0;
                margin-right: -$size_0;
            }
            &-mx {
                margin-left: $size_0;
                margin-right: $size_0;
            }
            &-my {
                margin-top: $size_0;
                margin-bottom: $size_0;
            }            
            &-neg{
                &-mx {
                    margin-left: -$size_0;
                    margin-right: -$size_0;
                }
                &-my {
                    margin-top: -$size_0;
                    margin-bottom: -$size_0;
                }  
            }
            &-mt { 
                margin-top: $size_0;
            }
            &-mb { 
                margin-bottom: $size_0;
            }
            &-ml { 
                margin-left: $size_0;
            }
            &-mr { 
                margin-right: $size_0;
            }        
            &-neg{
                &-mt { 
                    margin-top: -$size_0;
                }
                &-mb { 
                    margin-bottom: -$size_0;
                }
                &-ml { 
                    margin-left: -$size_0;
                }
                &-mr { 
                    margin-right: -$size_0;
                } 
            }

            &-p-0 { 
                padding-top: 0;
                padding-bottom: 0;
                padding-left: 0;
                padding-right: 0;
            }
            &-px-0 {
                padding-left: 0;
                padding-right: 0;
            }
            &-py-0 {
                padding-top: 0;
                padding-bottom: 0;
            }
            &-pt-0 { 
                padding-top: 0;
            }
            &-pb-0 { 
                padding-bottom: 0;
            }
            &-pl-0 { 
                padding-left: 0;
            }
            &-pr-0 { 
                padding-right: 0;
            }
    
            @each $size in $sizes_list {
                $i: index($sizes-list, $size);
                &-h-#{$i} { 
                    height: $size;
                }
                &-h-flex { 
                    height: 100%;
                }
                &-w-#{$i} { 
                    width: $size;
                }
                &-w-flex { 
                    width: 100%;
                }

                &-p-#{$i} { 
                    padding-top: $size;
                    padding-bottom: $size;
                    padding-left: $size;
                    padding-right: $size;
                }
                &-px-#{$i} {
                    padding-left: $size;
                    padding-right: $size;
                }
                &-py-#{$i} {
                    padding-top: $size;
                    padding-bottom: $size;
                }
                &-pt-#{$i} { 
                    padding-top: $size;
                }
                &-pb-#{$i} { 
                    padding-bottom: $size;
                }
                &-pl-#{$i} { 
                    padding-left: $size;
                }
                &-pr-#{$i} { 
                    padding-right: $size;
                }
                &-m-#{$i} { 
                    margin-top: $size;
                    margin-bottom: $size;
                    margin-left: $size;
                    margin-right: $size;
                }
                &-neg-m-#{$i} { 
                    margin-top: -$size;
                    margin-bottom: -$size;
                    margin-left: -$size;
                    margin-right: -$size;
                }
                &-mx-#{$i} {
                    margin-left: $size;
                    margin-right: $size;
                }
                &-my-#{$i} {
                    margin-top: $size;
                    margin-bottom: $size;
                }          
                &-neg{
                    &-mx-#{$i} {
                        margin-left: -$size;
                        margin-right: -$size;
                    }
                    &-my-#{$i} {
                        margin-top: -$size;
                        margin-bottom: -$size;
                    }  
                }
                &-mt-#{$i} { 
                    margin-top: $size;
                }
                &-mb-#{$i} { 
                    margin-bottom: $size;
                }
                &-ml-#{$i} { 
                    margin-left: $size;
                }
                &-mr-#{$i} { 
                    margin-right: $size;
                }      
                &-neg{
                    &-mt-#{$i} { 
                        margin-top: -$size;
                    }
                    &-mb-#{$i} { 
                        margin-bottom: -$size;
                    }
                    &-ml-#{$i} { 
                        margin-left: -$size;
                    }
                    &-mr-#{$i} { 
                        margin-right: -$size;
                    } 
                }
            }
    
            @each $ratio in $ratios_list {
                $i: index($ratios-list, $ratio);
                &-col-#{$i} { 
                    width: $ratio;
                }
                &-flex-#{$i} { 
                    flex: $i;
                }
            }
        }
    }
}

@media (min-width: 1920px) {

    [data-xl-sticky] {
        position: sticky;
    }

    .xl {
        &-h {
            height: $size_0;
        }
        &-w {
            height: $size_0;
        }

        &-p {
            padding-top: $size_0;
            padding-bottom: $size_0;
            padding-left: $size_0;
            padding-right: $size_0;
        }
        &-px {
            padding-left: $size_0;
            padding-right: $size_0;
        }
        &-py {
            padding-top: $size_0;
            padding-bottom: $size_0;
        }
        &-pt { 
            padding-top: $size_0;
        }
        &-pb { 
            padding-bottom: $size_0;
        }
        &-pl { 
            padding-left: $size_0;
        }
        &-pr { 
            padding-right: $size_0;
        }

        &-m { 
            margin-top: $size_0;
            margin-bottom: $size_0;
            margin-left: $size_0;
            margin-right: $size_0;
        }
        &-mx {
            margin-left: $size_0;
            margin-right: $size_0;
        }
        &-my {
            margin-top: $size_0;
            margin-bottom: $size_0;
        }
        &-mt { 
            margin-top: $size_0;
        }
        &-mb { 
            margin-bottom: $size_0;
        }
        &-ml { 
            margin-left: $size_0;
        }
        &-mr { 
            margin-right: $size_0;
        }

        @each $size in $sizes_list {
            $i: index($sizes-list, $size);
            &-h-#{$i} { 
                height: $size;
            }
            &-w-#{$i} { 
                width: $size;
            }
            &-p-#{$i} { 
                padding-top: $size;
                padding-bottom: $size;
                padding-left: $size;
                padding-right: $size;
            }
            &-px-#{$i} {
                padding-left: $size;
                padding-right: $size;
            }
            &-py-#{$i} {
                padding-top: $size;
                padding-bottom: $size;
            }
            &-pt-#{$i} { 
                padding-top: $size;
            }
            &-pb-#{$i} { 
                padding-bottom: $size;
            }
            &-pl-#{$i} { 
                padding-left: $size;
            }
            &-pr-#{$i} { 
                padding-right: $size;
            }
            &-m-#{$i} { 
                margin-top: $size;
                margin-bottom: $size;
                margin-left: $size;
                margin-right: $size;
            }
            &-mx-#{$i} {
                margin-left: $size;
                margin-right: $size;
            }
            &-my-#{$i} {
                margin-top: $size;
                margin-bottom: $size;
            }
            &-mt-#{$i} { 
                margin-top: $size;
            }
            &-mb-#{$i} { 
                margin-bottom: $size;
            }
            &-ml-#{$i} { 
                margin-left: $size;
            }
            &-mr-#{$i} { 
                margin-right: $size;
            }
        }

        @each $ratio in $ratios_list {
            $i: index($ratios-list, $ratio);
            &-col-#{$i} { 
                width: $ratio;
            }
            &-flex-#{$i} { 
                flex: $i;
            }
        }
    }
}