@use 'sass:list';
@use 'sass:map';
@import './common/colors.scss';

$colors: (
    'white': ('color': $white, 'text': $black), 
    'dark-white': ('color': $dark-white, 'text': $black), 
    'white-5-opacity': ('color': $white-5-opacity, 'text': $black),
    'white-10-opacity': ('color': $white-10-opacity, 'text': $black),
    'white-20-opacity': ('color': $white-20-opacity, 'text': $black),
    'white-40-opacity': ('color': $white-40-opacity, 'text': $black),
    'white-50-opacity': ('color': $white-50-opacity, 'text': $black),
    'white-60-opacity': ('color': $white-60-opacity, 'text': $black),
    'black': ('color': $black, 'text': $white),
    'black-5-opacity': ('color': $black-5-opacity, 'text': $black),
    'black-10-opacity': ('color': $black-10-opacity, 'text': $black),
    'black-20-opacity': ('color': $black-20-opacity, 'text': $white),
    'black-40-opacity': ('color': $black-40-opacity, 'text': $white),
    'black-50-opacity': ('color': $black-50-opacity, 'text': $white), 
    'gray': ('color': $gray, 'text': $black), 
    'gray-darker': ('color': $gray-darker, 'text': $black), 
    'light-gray': ('color': $light-gray, 'text': $black),
    'dark-gray': ('color': $dark-gray, 'text': $white),
    'green': ('color': $green, 'text': $white), 
    'light-green': ('color': $light-green, 'text': $black),  
    'bright-green': ('color': $bright-green, 'text': $black), 
    'dark-green': ('color': $dark-green, 'text': $white),
    'blue': ('color': $blue, 'text': $white), 
    'light-blue': ('color': $light-blue, 'text': $black), 
    'bright-blue': ('color': $bright-blue, 'text': $white),
    'yellow': ('color': $yellow, 'text': $black),
    'light-yellow': ('color': $light-yellow, 'text': $black), 
    'pink': ('color': $pink, 'text': $white), 
    'light-pink': ('color': $light-pink, 'text': $black), 
    'bright-pink': ('color': $bright-pink, 'text': $white), 
    'coral': ('color': $coral, 'text': $white),
    'extra-light-coral': ('color': $extra-light-coral, 'text': $black),
    'transparent': ('color': transparent, 'text': $white),
    'transparent-black': ('color': transparent, 'text': $black),
    'tp-white': ('color': transparent, 'text': $white),
    'tp-black': ('color': transparent, 'text': $black),
    'mmco-color': ('color': $mmco-color, 'text': $white),
);

.block {
    background-color: $white;
    border: none;
    outline: none;
    color: $black;

    transition: top 0.2s ease-in-out, 
                opacity 0.2s ease-in-out, 
                background-color 0.2s ease-in-out;
                // margin 0.2s ease-in-out; 

    &.visible {
        overflow: auto;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    &.box-shadow {
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 7px 13px rgba(0, 0, 0, 0.14);
    }

    &.btn-box-shadow {
        box-shadow: 0px 11px 53px 0px #00000014;
    }

    &.popup-box-shadow {
        box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.14), 0px 1px 2px 0px rgba(0, 0, 0, 0.04);
    }
    
    &-to-top {
        z-index: 50;
        position: fixed;
        top: 4px;
    }

    &-sticky-top {
        z-index: 50;
        position: sticky;
        top: 4px;
    }

    &-absolute-top {
        z-index: 50;
        position: absolute;
        top: 0px;
    }

    &-absolute-bottom {
        // z-index: 50;
        position: absolute;
        bottom: 0px;
    }

    &-static-top {
        position: relative;
        z-index: 50;
    }   

    &-hint {
        position: absolute;
        right: calc(-50% - 19px);
        top: -8px;
        width: calc(50% - 3px);

        opacity: 0;

        transition: opacity 0.3s ease-in-out;

        &-left {
            position: absolute;
            left: calc(-50% - 19px);
            top: -8px;
            width: calc(50% - 3px);

            opacity: 0;
            
            transition: opacity 0.3s ease-in-out;
        }
    }

    @each $size_name, $breakpoint in $breakpoints {        
        @media (max-width: $breakpoint) {
            &-#{$size_name}-hint, &-#{$size_name}-hint-left {
                position: absolute;
                width: 316px;
                top: 14px;
                right: 14px;
                margin: 0;
                box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 7px 13px rgba(0, 0, 0, 0.14);

                opacity: 0;
            }   
            
            &-#{$size_name}-hint-left {
                right: unset;
                left: 14px;
            }
        }
    }
    

    @each $color_1, $color_2 in $colors {  
        &[data-color=#{$color_1}] {
            background-color: map.get($color_2, 'color');
            & h1:not([data-color]), 
            & h2:not([data-color]), 
            & h3:not([data-color]), 
            & h4:not([data-color]), 
            & h5:not([data-color]), 
            & h6:not([data-color]), 
            & p:not([data-color]) {
                color: map.get($color_2, 'text');
            }
        }

        &.hover-#{$color_1}:hover {
            background-color: map.get($color_2, 'color');
        }
    }   

    @each $color_1, $color_2 in $colors {  
        &[data-color=#{$color_1}] {
            background-color: map.get($color_2, 'color');
            & > * > h1:not([data-color]), 
            & > * > h2:not([data-color]), 
            & > * > h3:not([data-color]), 
            & > * > h4:not([data-color]), 
            & > * > h5:not([data-color]), 
            & > * > h6:not([data-color]), 
            & > * > p:not([data-color]) {
                color: map.get($color_2, 'text');
            }
        }
    }

    &:not([data-color]) > * > h1:not([data-color]), 
    &:not([data-color]) > * > h2:not([data-color]), 
    &:not([data-color]) > * > h3:not([data-color]), 
    &:not([data-color]) > * > h4:not([data-color]), 
    &:not([data-color]) > * > h5:not([data-color]), 
    &:not([data-color]) > * > h6:not([data-color]), 
    &:not([data-color]) > * > p:not([data-color]) {
        color: $black;
    }

    @each $color_1, $color_2 in $colors {  
        &[data-color=#{$color_1}] {
            & > h1:not([data-color]), 
            & > h2:not([data-color]), 
            & > h3:not([data-color]), 
            & > h4:not([data-color]), 
            & > h5:not([data-color]), 
            & > h6:not([data-color]), 
            & > p:not([data-color]) {
                color: map.get($color_2, 'text');
            }
        }
        @for $i from 1 through 11 {
            &[data-color='tp-#{$color_1}-#{$i}'] {
                background-color: transparent;
                border: solid #{$i}px map.get($color_2, 'color');
            }
            &[data-border='#{$color_1}-#{$i}'] {
                border: solid #{$i}px map.get($color_2, 'color');
            }
            &[data-border='outer-#{$color_1}-#{$i}'] {
                border: solid #{$i}px map.get($color_2, 'color');
                margin: -#{$i}px;
            }
        }
    }

    &:not([data-color]) > h1:not([data-color]), 
    &:not([data-color]) > h2:not([data-color]), 
    &:not([data-color]) > h3:not([data-color]), 
    &:not([data-color]) > h4:not([data-color]), 
    &:not([data-color]) > h5:not([data-color]), 
    &:not([data-color]) > h6:not([data-color]), 
    &:not([data-color]) > p:not([data-color]) {
        color: $black;
    }

    &[data-color='tp-white'] {
        border: solid 1px $white;
    }
    &[data-color='tp-black'] {
        border: solid 1px $black;
    }
}

[data-with-hint] {
    position: relative;

    & > .hint-tail {
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
    }

    & > .block-hint, & > .block-hint-left {
        pointer-events: none;

        & * {
            pointer-events: none;
        }
    }
}

[data-with-hint='true'] {
    z-index: 2;
    pointer-events: none;

    & :not(.hint-active-btn) {
        pointer-events: none;
    }

    // & button:not(.hint-active-btn) {
    //     opacity: 0;
    // }    

    & > .hint-tail {
        opacity: 1;

        &.hint-hidden {
            opacity: 0;
        }
    }

    & > .hint-borders {
        $border-width: 8px;
        position: absolute;
        top: -$border-width;
        bottom: -$border-width;
        left: -$border-width;
        right: -$border-width;
        border: solid $border-width $green;
        border-radius: 32px;
    }

    & .hint-active-btn {
        pointer-events: all;
    }

    & > .block-hint, & > .block-hint-left {
        opacity: 1; 
        z-index: 4;   

        &.hint-hidden {
            opacity: 0;

            & * {
                pointer-events: none;
            } 
        }
    }
}

path {
    @each $color_1, $color_2 in $colors {  
        &[data-color=#{$color_1}] {
            fill: map.get($color_2, 'color');
        }
    }
}

h1, h2, h3, h4, h5, h6, p, span {
    @each $color_1, $color_2 in $colors {  
        &[data-color=#{$color_1}] {
            color: map.get($color_2, 'color');
        }
    }
}

textarea {
    @each $color_1, $color_2 in $colors {  
        &[data-color=#{$color_1}] {
            background-color: map.get($color_2, 'color');
            &:not([data-text-color]) {
                color: map.get($color_2, 'text');
            }

            &::placeholder {
                color: rgba(map.get($color_2, 'text'), .4);
            }
        }        

        &[data-text-color=#{$color_1}] {
            color: map.get($color_2, 'color');
        }
    }
}

input:not(.mobile:hover):hover:not(:read-only), input:not(:read-only):focus {
    & + .custom-hover, & + img {
        opacity: 0.4;
    }
}

path.hover-img {
    transition: stroke 0.2s ease-in-out;
}

.input-block {
    background-color: $light-gray;

    border-radius: 8px;
    
    flex-wrap: nowrap;
        
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }

    &.mobile > *:last-child {
        margin-right: 14px;
    }

    &.desktop > *:last-child {
        margin-right: 14px;
    }

    & > input {
        background-color: transparent;
        outline: none;
        border: none;
    }
}

.btn-symbol {
    flex-shrink: 0;
    border-radius: 50%;
    background: transparent;

    border: 2px solid $black; 

    @each $color_1, $color_2 in $colors {  
        &[data-color=#{$color_1}] {
            border-color: map.get($color_2, 'color');
        }
    }

    &.filled {
        background-color: $black;
        border: none;
        color: $white;

        @each $color_1, $color_2 in $colors {  
            &[data-color=#{$color_1}] {
                background-color: map.get($color_2, 'color');
            }
        }
    }

    & > img:not(.not-scale) {
        height: 60%;
        width: 60%;
    }

    &.back {
        transform: rotate(180deg);
    }

    &.close {
        transform: rotate(45deg);
    }

    &.under {
        &-left {
            margin-left: -100%;
        }
    }
}

.logo { 
    overflow: visible; 
    aspect-ratio: 1;
    height: auto;
    // position: relative;

    &, & * {
    transition: width 0.2s ease-in-out,
                height 0.2s ease-in-out,
                border-radius 0.2s ease-in-out,
                margin 0.2s ease-in-out,
                font-size 0.2s ease-in-out;
    }
    
    &.box-shadow {
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04), 0px 0px 13px rgba(0, 0, 0, 0.14);;
    }
    
    & > .logo-left, & > .logo-right {
        width: 50%;
        height: 100%;
    }

    & > .cover {
        // position: absolute;
        // top: 0;
        // left: 0;
        width: 100%;
        height: 100%; 
        margin-top: -100%;      
    }

    &.size-0 {
        border-radius: 9px;
        width: 100%;

        & > .logo-left {
            border-radius: 8% 0 0 8px;
        }
        & > .logo-right {
            border-radius: 0 8px 8px 0;
        }
        & > .cover {
            border-radius: 8px;
        }

        &.circled {
            border-radius: 15px;
    
            & > .logo-left {
                border-radius: 14px 0 0 14px;
            }
            & > .logo-right {
                border-radius: 0 14px 14px 0;
            }
            & > .cover {
                border-radius: 14px;
            }
        }
    }

    $widths: 24px, 32px, 32px, 40px, 58px, 94px, 123px, 148px, 198px, 235px, 271px, 500px;
    $border-radii: 4px, 4px, 4px, 8px, 8px, 8px, 8px, 8px, 8px, 8px, 14px, 14px;
    $larger-radii: 4px, 4px, 4px, 8px, 14px, 14px, 14px, 14px, 14px, 14px, 18px, 18px;

    &.size {
        @for $i from 1 through 11 {
            $width: list.nth($widths, $i);
            $border-radius: list.nth($border-radii, $i);
            $larger-radius: list.nth($larger-radii, $i);
            &-#{$i} {
                width: $width;
                border-radius: calc($border-radius + 1px);
                & > .logo-left {
                    border-radius: $border-radius 0 0 $border-radius;
                }
                & > .logo-right {
                    border-radius: 0 $border-radius $border-radius 0;
                }
                & > .cover {
                    border-radius: $border-radius;
                }

                &.circled {
                    border-radius: calc($larger-radius + 1px);
                    & > .logo-left {
                        border-radius: $larger-radius 0 0 $larger-radius;
                    }
                    & > .logo-right {
                        border-radius: 0 $larger-radius $larger-radius 0;
                    }
                    & > .cover {
                        border-radius: $larger-radius;
                    }
                }
            }
        }
    }
}

.background  {
    background-color: $light-gray; 
    min-height: 100vh;       

    &[data-color='white'] {
        background-color: $white;
    }
}
