@import './colors.scss';
@import './vars.scss';

$breakpoints: ("lg": 1920px, "md": 1280px, "sm": 1024px, "xs": 768px);

* {
    padding: 0;
    margin: 0;
    
    box-sizing: border-box;
    &.content-box {
        box-sizing: content-box;
    }

    font-family: Manrope, Arial, sans-serif;
    line-height: 140%;
    letter-spacing: 0;

    background-size: cover;
    background-position: center;

    &.noshrink {
        flex-shrink: 0;
    }

    scroll-behavior: smooth;

    &.inline {
        display: inline-block;
    }
}

body {
    background-color: $light-gray;
}

.centered {
    display: flex;
    align-items: center;
    justify-content: center;

    &[hidden] {
        display: none;
    }
}

.x-auto {
    overflow-x: auto;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
}

.y-auto {
    overflow-y: auto;
    &:not(.mobile) {
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &.x-auto {
        overflow-x: auto;
    }

    &[hidden] {
        display: none;
    }

    &.mobile {
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    &.nowrap {
        flex-wrap: nowrap;
        
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }

        @each $size_name, $breakpoint in $breakpoints {        
            @media (max-width: $breakpoint) {
                & .#{$size_name}-wrap {
                    flex-wrap: wrap;

                    & > * {
                        flex-basis: 100%;
                    }
                }
            }
        }
    }

    &.noscroll {
        overflow: visible;
    }

    &.hidden {
        overflow: hidden;
    }

    &.nogap {
        gap: 0;
    }

    &.noshrink > * {
        flex-shrink: 0;
    }
    
    justify-content: space-between;
    gap: 8px;

    &.gap {
        &-0 {
            gap: 4px;
        }
        &-1 {
            gap: 8px;
        }
        &-2 {
            gap: 14px;
        }
        &-3 {
            gap: 24px;
        }
        &-4 {
            gap: 38px;
        }
        &-5 {
            gap: 58px;
        }
        &-6 {
            gap: 94px;
        }
    }

    @each $size_name, $breakpoint in $breakpoints {
        @media (max-width: $breakpoint) {
            &.#{$size_name}-gap {
                &-0 {
                    gap: 4px;
                }
                &-1 {
                    gap: 8px;
                }
                &-2 {
                    gap: 14px;
                }
                &-3 {
                    gap: 24px;
                }
                &-4 {
                    gap: 38px;
                }
                &-5 {
                    gap: 58px;
                }
                &-6 {
                    gap: 94px;
                }
            }
            &-nogap {
                gap: 0;
            }
        }
    }

    &.left {
        // max-width: 100%;
        justify-content: start;
    }

    &.center {
        justify-content: center;
    }

    &.right {
        // max-width: 100%;
        justify-content: end;
    }

    align-items: center;

    &.top {
        align-items: start;
    }

    &.bottom {
        align-items: end;
    }

    &.osmos-logo {
        gap: 1px;

        &-desktop {
            gap: 1px;

            & > img {
                transition: height 0.3s ease-in-out;
            }
        }
    }
}

.column {
    display: flex;
    flex-direction: column;

    // max-width: 100%;

    justify-content: space-between; 
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }

    &[hidden] {
        display: none;
    } 

    &.auto {
        overflow: auto;
    }

    &.noscroll {
        overflow: visible;
    }  

    &.top {
        max-height: 100%;
        justify-content: start;
    }

    &.center {
        justify-content: center;
    }

    &.bottom {
        max-height: 100%;
        justify-content: end;
    }

    align-items: center;

    &.left {
        align-items: start;
    }

    &.right{
        align-items: end;
    }
}

.body {
    position: relative;
    display: flex;
    box-sizing: border-box;
    min-height: 100vh;
    min-width: 360px;    

    &.left {
        flex-direction: row;
    }
    &.right {
        flex-direction: row-reverse;
    }
    &.top {
        flex-direction: column;
    }
    &.bottom {
        flex-direction: column-reverse;
    }

    &-container {
        &.desktop {
            min-width: 768px;
        }

        & > .body {
            max-width: 1280px;
            width: 100%;
        }
    }
}

@keyframes shining {
    100% {
        background-position: 80px 0;
    }
}

h1, h2, h3, h4, h5, h6 { 
    font-weight: 600;
    letter-spacing: 0;
}

p {
    font-weight: 400;
}

h1, h2, h3, h4, h5, h6, p, span {  
    max-width: 100%;
    text-align: left;
    transition: color 0.1s ease-in-out; 
    word-break: break-word;

    &.ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    
    &.hover-blue:hover {
        color: $blue;
        cursor: default;
    }

    &.center {
        width: 100%;
        text-align: center;
    }

    &.right {
        text-align: end;
    }

    &.light {
        font-weight: 300;
    }

    &.regular {
        font-weight: 400;
    }

    &.medium {
        font-weight: 500;
    }

    &.semibold {
        font-weight: 600;
        letter-spacing: -0.01em;
    }

    &.bold {
        font-weight: 700;
    }

    &.ebold {
        font-weight: 800;
    }

    &.narrow {
        line-height: 100%;
    }

    &.seminarrow {
        line-height: 120%;
    }

    &.nowrap {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
}

.sticky {
    &-bottom {
        pointer-events: none;
        z-index: 10;
        position: sticky;
        bottom: 0;

        & > * > * {
            pointer-events: all;
        }
    }
}

a {
    text-decoration: none;
    
    &[type='button'] {
        cursor: pointer;
    }
}

h1, .text-1, [data-size='1'], textarea[data-size='1'], input[data-size='1'] {
    font-size: 94px;
}

h2, .text-2, [data-size='2'], textarea[data-size='2'], input[data-size='2'] {
    font-size: 58px;
}

h3, .text-3, [data-size='3'], textarea[data-size='3'], input[data-size='3'] {
    font-size: 40px;
}

h4, .text-4, [data-size='4'], textarea[data-size='4'], input[data-size='4'] {
    font-size: 24px;
}

h5, .text-5, [data-size='5'], textarea[data-size='5'], input[data-size='5'] {
    font-size: 18px;
}

h6, .text-6, [data-size='6'], textarea[data-size='6'], input[data-size='6'] {
    font-size: 14px;
}

.text-7, [data-size='7'], textarea[data-size='7'], input[data-size='7'] {
    font-size: 12px;
}

.text-8, [data-size='8'], textarea[data-size='8'], input[data-size='8'] {
    font-size: 16px;
}

.text-9, [data-size='9'], textarea[data-size='9'], input[data-size='9'] {
    font-size: 32px;
}

.after {
    display: none;
}
body:not([data-locale='ru']) .only-latin {
    border: 2px solid $coral;
    &::placeholder, & input::placeholder {
        color: $coral;
    }
    & + .after {
        display: flex;
        font-size: 14px;
        &:before {
            content: "To broaden your match possibilities, ensure you only use the English language";
        }
    }
}

form.invalid-form .valid, [data-invalid-form='true'] .valid {
    border: 2px solid transparent;
}

body:not([data-locale='ru']) form.invalid-form .invalid, 
body:not([data-locale='ru']) [data-invalid-form='true'] .invalid,
body:not([data-locale='ru']) form.invalid-form .only-latin, 
body:not([data-locale='ru']) [data-invalid-form='true'] .only-latin {
    border: 2px solid $coral;
    &::placeholder, & input::placeholder {
        color: $coral;
    }

    & + .after {
        display: flex;
        font-size: 14px;
        &:before {
            content: "This is the required field";
        }
    }   

    &.only-latin + .after:before {
        content: "To broaden your match possibilities, ensure you only use the English language";
    }

    & + .after-hint {
        color: $coral;
    }

    & input:not(.not-invalid), & textarea:not(.not-invalid) {
        color: $coral;
    }
}

textarea, input {
    outline: none;
    border: none;
    resize: none;
    width: 100%;

    &:not([data-color]) {
        background-color: $light-gray;
    }

    &.ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &:not([data-color])::placeholder {
        color: $black-50-opacity;
    }
    font-size: 16px;

    &[data-error='true'] {
        color: $coral;
    }

    &[type='file'] {
        width: 0;
        height: 0;
        position: fixed;
        opacity: 0;
        pointer-events: none;
    }

    &[type='number'] {
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
        }
        appearance: textfield;
    }
}

textarea.desktop {
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
}

a.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

input.mobile {
    border-radius: 8px;
    height: 48px;
    padding: 0 14px;
}

input.desktop {
    border-radius: 8px;
    height: 58px;
    padding: 0 14px;
}

input[type="range"] {
    -webkit-appearance: none;
    appearance: none;
    height: 8px;
    background: $light-gray;
    background-image: linear-gradient($green, $green);
    border-radius: 5px;
    // background-image: linear-gradient(#ff4500, #ff4500);
    background-size: 70% 100%;
    background-repeat: no-repeat;
    border-left: solid 11px $green;
    border-right: solid 11px $light-gray;
    &[data-full='true'] {
        border-right: solid 11px $green;
    }

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 38px;
        width: 38px;
        border-radius: 50%;
        background-color: $green;
        box-shadow: 0px 1px 3px 0px #00000024;
    } 
    &::-moz-range-thumb {
        -webkit-appearance: none;
        appearance: none;
        height: 38px;
        width: 38px;
        border-radius: 50%;
        background-color: $green;
        box-shadow: 0px 1px 3px 0px #00000024;
    }
    &::-ms-thumb {
        -webkit-appearance: none;
        appearance: none;
        height: 38px;
        width: 38px;
        border-radius: 50%;
        background-color: $green;
        box-shadow: 0px 1px 3px 0px #00000024;
    }

    &.range-mobile::-webkit-slider-thumb {
        height: 24px;
        width: 24px;
    } 
    &.range-mobile::-moz-range-thumb {
        height: 24px;
        width: 24px;
    }
    &.range-mobile::-ms-thumb {
        height: 24px;
        width: 24px;
    }
}

.checkbox-radio {
    border: solid 2px $black;
    opacity: 0.4;

    &[data-error='true']:not([data-checked='true']) {
        border: solid 2px $coral;
        opacity: 1;
    }

    &[data-color='white']:not([data-checked='true']) {
        border: solid 2px $white;
    }

    & > img {
        width: 50%;
        opacity: 0;
    }

    & > .checkbox-center {
        background-color: $black-10-opacity;
    }
    &[data-color='white'] > .checkbox-center {
        background-color: $white-10-opacity;
    }

    &[data-checked='true'] {
        border: none;
        background-color: $green;
        opacity: 1;

        & > img {
            opacity: 1;
        }

        & > .checkbox-center {
            background-color: $white;
        }
    }

    &[data-checked='false'] + span {
        font-size: 18px;
        font-weight: 400;
        color: $dark-gray;
    }
}

button, button * {
    outline: none;
    border: none;
    background-color: transparent;
    color: $black;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
    cursor: pointer;
    pointer-events: auto;
}

[data-lines] {
    display: -webkit-box; 
    -webkit-box-orient: vertical;
    overflow: hidden; 

    & p.article, & span.article {
        display: inline;

        &:not(:last-child)::after {
            content: " \A\A";
            white-space: pre;
        }
    }

    &[data-scroll='true'] {
        overflow-y: scroll;
        max-height: 100%;

        &:not(.mobile) {
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    @for $i from 1 through 20 {
        &[data-lines='#{$i}']:not([data-scroll='true']) {
            -webkit-line-clamp: $i;
            line-clamp: $i;
        }
    }
}

li::marker {
    margin-left: 40px;
}

.appear-on-hover {
    transition: opacity 0,3s ease-in-out;
    opacity: 0;

    &:hover {
        opacity: 1;
    }
}
