@import './common/colors.scss';

.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 100vh;
    z-index: 200;

    background-color: #00000000; 

    transition: background-color 0.3s ease-in-out, backdrop-filter 0.3s ease-in-out;

    & > * {
        transition: opacity 0.3s ease-in-out;
    } 

    flex-direction: column-reverse;
    
    &[data-popup-shown='false'] { 
        background-color: #00000000; 
        backdrop-filter: blur(0px);
        & > * {
            opacity: 0;
        }     
        // animation: popupHide 0.3s ease-in-out forwards;
    }

    &[data-popup-shown='true'] {
        background-color: #00000066;
        &.blur-bg {
            backdrop-filter: blur(5px);
        }
        & > * {
            opacity: 1;
        } 
        // animation: popupShow 0.3s ease-in-out forwards;
    }

    &-content {
        // position: fixed;        
        z-index: 201;
        overflow: hidden;

        & .select-list {
            max-height: 300px;
            overflow: scroll;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }
        }

        transition: max-height 0.3s ease-out, 
                    bottom 0.3s ease-out;
        
        &:not([data-black='true']) {
            background-color: $white;

            width: 100%;
        }

        &[data-black='true'] {
            background-color: $black;

            width: calc(100% - 60px);
            max-width: 340px;
            right: 30px;

            &[data-white='true'] {
                background-color: $white;
                max-width: 430px;
                box-shadow: 0px 0px 13px 0px rgba(0, 0, 0, 0.14), 0px 1px 2px 0px rgba(0, 0, 0, 0.04);
            }

            &[data-transparent='true'] {
                background-color: transparent;
                max-width: 430px;
                box-shadow: unset;
                padding: 0;
                overflow: visible;
            }
        }

        &[data-blue='true'] {
            background-color: $blue;
        }
    }

    &-content-right {
        position: fixed;        
        z-index: 201;
        overflow: auto;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }

        // & > * {            
        //     min-width: max-content;
        // }

        & .select-list {
            max-width: 800px;
            overflow: scroll;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }
        }

        transition: max-width 0.4s ease-in-out, 
                    right 0.4s ease-in-out;
        
        &:not([data-black='true']) {
            background-color: $white;

            height: 100%;
        }

        &[data-black='true'] {
            background-color: $black;

            top: 30px;
            bottom: 30px;
        }
    }
}

.center-popup {
    max-width: calc(100vw - 116px);
}

.slider {
    & > div {
        width: 109px;
        height: 4px;
        border-radius: 3px;
        background-color: $gray;
    }
}

@keyframes popupHide {
    0% {
        // visibility: visible;
        background-color: #00000033;
    }
    100% {
        // visibility: visible;
        background-color: #00000000;        
    }
}

@keyframes popupShow {
    0% {
        // visibility: visible;
        background-color: #00000000;
    }
    100% {
        // visibility: visible;
        background-color: #00000033;
    }
}

svg.loading {
    & > g > circle {
        animation: 3s linear snake infinite
    }
    animation: 2s linear rotator infinite;
}

@keyframes rotator {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes snake {
    0% {
        stroke-dashoffset: 253%;
    }
    50% {
        stroke-dashoffset: 0%;
    }
    100% {
        stroke-dashoffset: -253%;
    }
}

.video {
    position: relative;
    & > * {
        position: absolute;
        &.video-1 {
            z-index: 5;
            animation: video-1 5s linear infinite;
        } 
        &.video-2 {
            z-index: 4;
            animation: video-2 5s linear infinite;
        }
        &.video-3 {
            z-index: 1;
            animation: video-3 5s linear infinite;
        }
        &.video-4 {
            z-index: 0;
            animation: video-4 5s linear infinite;
        }
        &.video-5 {
            z-index: 3;
            animation: video-5 5s linear infinite;
        }
        &.video-6 {
            z-index: 2;
            animation: video-6 5s linear infinite;
        }

        &.mobile-video-1 {
            z-index: 5;
            animation: video-1 5s linear infinite;
        } 
        &.mobile-video-2 {
            z-index: 4;
            animation: video-2 5s linear infinite;
        }
        &.mobile-video-3 {
            z-index: 1;
            animation: video-3 5s linear infinite;
        }
        &.mobile-video-4 {
            z-index: 0;
            animation: video-4 5s linear infinite;
        }
        &.mobile-video-5 {
            z-index: 3;
            animation: video-5 5s linear infinite;
        }
        &.mobile-video-6 {
            z-index: 2;
            animation: video-6 5s linear infinite;
        }
    } 
}

@keyframes video-1 {
    0% {
        opacity: 1;
        left: 0;
        transform: scale(1.2);
    }
    25% {
        opacity: 1;
        left: 0;
        transform: scale(1.2);
    }
    28% {
        opacity: 1;
        left: -10px;
        transform: scale(1.2);
    }
    47% {
        opacity: 0;
        left: 68px;
        transform: scale(1);
    }
    50% {
        opacity: 0;
        left: 58px;
        transform: scale(1);
    }
    53% {
        opacity: 0;
        left: 68px;
        transform: scale(1);
    }
    72% {
        opacity: 1;
        left: -10px;
        transform: scale(1.2);
    }
    75% {
        opacity: 1;
        left: 0;
        transform: scale(1.2);
    }
    100% {
        opacity: 1;
        left: 0;
        transform: scale(1.2);
    }
}
@keyframes video-2 {
    0% {
        opacity: 0;
        left: 0;
        transform: scale(1.2);
    }
    25% {
        opacity: 0;
        left: 0;
        transform: scale(1.2);
    }
    28% {
        opacity: 0;
        left: -10px;
        transform: scale(1.2);
    }
    47% {
        opacity: 1;
        left: 68px;
        transform: scale(1);
    }
    50% {
        opacity: 1;
        left: 58px;
        transform: scale(1);
    }
    53% {
        opacity: 1;
        left: 68px;
        transform: scale(1);
    }
    72% {
        opacity: 0;
        left: -10px;
        transform: scale(1.2);
    }
    75% {
        opacity: 0;
        left: 0;
        transform: scale(1.2);
    }
    100% {
        opacity: 0;
        left: 0;
        transform: scale(1.2);
    }
}
@keyframes video-3 {
    0% {
        opacity: 1;
        left: 0;
        transform: scale(1.2);
    }
    3% {
        opacity: 1;
        left: -10px;
        transform: scale(1.2);
    }
    22% {
        opacity: 0;
        left: 68px;
        transform: scale(1);
    }
    25% {
        opacity: 0;
        left: 58px;
        transform: scale(1);
    }
    28% {
        opacity: 0;
        left: 68px;
        transform: scale(1);
    }
    47% {
        opacity: 1;
        left: -10px;
        transform: scale(1.2);
        z-index: 7;
    }
    50% {
        opacity: 1;
        left: 0;
        transform: scale(1.2);
    }
    53% {
        z-index: 1;
    }
    75% {
        opacity: 1;
        left: 0;
        transform: scale(1.2);
    }
    100% {
        opacity: 1;
        left: 0;
        transform: scale(1.2);
    }
}
@keyframes video-4 {
    0% {
        opacity: 0;
        left: 0;
        transform: scale(1.2);
    }
    3% {
        opacity: 0;
        left: -10px;
        transform: scale(1.2);
    }
    22% {
        opacity: 1;
        left: 68px;
        transform: scale(1);
    }
    25% {
        opacity: 1;
        left: 58px;
        transform: scale(1);
    }
    28% {
        opacity: 1;
        left: 68px;
        transform: scale(1);
    }
    47% {
        opacity: 0;
        left: -10px;
        transform: scale(1.2);
    }
    50% {
        opacity: 0;
        left: 0;
        transform: scale(1.2);
    }
    75% {
        opacity: 0;
        left: 0;
        transform: scale(1.2);
    }
    100% {
        opacity: 0;
        left: 0;
        transform: scale(1.2);
    }
}
@keyframes video-5 {
    0% {
        opacity: 1;
        left: 0;
        transform: scale(1.2);
    }
    3% {
        opacity: 1;
        left: 10px;
        transform: scale(1.2);
    }
    22% {
        opacity: 1;
        left: -68px;
        transform: scale(1);
    }
    25% {
        opacity: 1;
        left: -58px;
        transform: scale(1);
    }
    50% {
        opacity: 0;
        left: -58px;
        transform: scale(1);
    }
    53% {
        opacity: 0;
        left: -68px;
        transform: scale(1);
    }
    72% {
        opacity: 0;
        left: 10px;
        transform: scale(1.2);
    }
    75% {
        opacity: 1;
        left: 0;
        transform: scale(1.2);
    }
    100% {
        opacity: 1;
        left: 0;
        transform: scale(1.2);
    }
}
@keyframes video-6 {
    0% {
        opacity: 0;
        left: 0;
        transform: scale(1.2);
    }
    3% {
        opacity: 0;
        left: 10px;
        transform: scale(1.2);
    }
    22% {
        opacity: 0;
        left: -68px;
    }
    25% {
        opacity: 0;
        left: -58px;
        transform: scale(1);
    }
    50% {
        opacity: 1;
        left: -58px;
        transform: scale(1);
    }
    53% {
        opacity: 1;
        left: -68px;
        transform: scale(1);
    }
    72% {
        opacity: 1;
        left: 10px;
        transform: scale(1.2);
    }
    75% {
        opacity: 0;
        left: 0;
        transform: scale(1.2);
    }
    100% {
        opacity: 0;
        left: 0;
        transform: scale(1.2);
    }
}

@keyframes mobile-video-1 {
    0% {
        opacity: 1;
        transform: translate(0, 0);
    }
    25% {
        opacity: 1;
        transform: translate(0, 0);
    }
    28% {
        opacity: 1;
        transform: translate(-10px, 0);
    }
    47% {
        opacity: 0;
        transform: translate(68px, 0);
    }
    50% {
        opacity: 0;
        transform: translate(58px, 0);
    }
    53% {
        opacity: 0;
        transform: translate(68px, 0);
    }
    72% {
        opacity: 1;
        transform: translate(-10px, 0);
    }
    75% {
        opacity: 1;
        transform: translate(0, 0);
    }
    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}
@keyframes mobile-video-2 {
    0% {
        opacity: 0;
        transform: translate(0, 0);
    }
    25% {
        opacity: 0;
        transform: translate(0, 0);
    }
    28% {
        opacity: 0;
        transform: translate(-10px, 0);
    }
    47% {
        opacity: 1;
        transform: translate(68px, 0);
    }
    50% {
        opacity: 1;
        transform: translate(58px, 0);
    }
    53% {
        opacity: 1;
        transform: translate(68px, 0);
    }
    72% {
        opacity: 0;
        transform: translate(-10px, 0);
    }
    75% {
        opacity: 0;
        transform: translate(0, 0);
    }
    100% {
        opacity: 0;
        transform: translate(0, 0);
    }
}
@keyframes mobile-video-3 {
    0% {
        opacity: 1;
        transform: translate(0, 0);
    }
    3% {
        opacity: 1;
        transform: translate(-10px, 0);
    }
    22% {
        opacity: 0;
        transform: translate(68px, 0);
    }
    25% {
        opacity: 0;
        transform: translate(58px, 0);
    }
    28% {
        opacity: 0;
        transform: translate(68px, 0);
    }
    47% {
        opacity: 1;
        transform: translate(-10px, 0);
    }
    50% {
        opacity: 1;
        transform: translate(0, 0);
    }
    75% {
        opacity: 1;
        transform: translate(0, 0);
    }
    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}
@keyframes mobile-video-4 {
    0% {
        opacity: 0;
        transform: translate(0, 0);
    }
    3% {
        opacity: 0;
        transform: translate(-10px, 0);
    }
    22% {
        opacity: 1;
        transform: translate(68px, 0);
    }
    25% {
        opacity: 1;
        transform: translate(58px, 0);
    }
    28% {
        opacity: 1;
        transform: translate(68px, 0);
    }
    47% {
        opacity: 0;
        transform: translate(-10px, 0);
    }
    50% {
        opacity: 0;
        transform: translate(0, 0);
    }
    75% {
        opacity: 0;
        transform: translate(0, 0);
    }
    100% {
        opacity: 0;
        transform: translate(0, 0);
    }
}
@keyframes mobile-video-5 {
    0% {
        opacity: 1;
        transform: translate(0, 0);
    }
    3% {
        opacity: 1;
        transform: translate(10px, 0);
    }
    22% {
        opacity: 1;
        transform: translate(-68px, 0);
    }
    25% {
        opacity: 1;
        transform: translate(-58px, 0);
    }
    50% {
        opacity: 0;
        transform: translate(-58px, 0);
    }
    53% {
        opacity: 0;
        transform: translate(-68px, 0);
    }
    72% {
        opacity: 0;
        transform: translate(10px, 0);
    }
    75% {
        opacity: 1;
        transform: translate(0, 0);
    }
    100% {
        opacity: 1;
        transform: translate(0, 0);
    }
}
@keyframes mobile-video-6 {
    0% {
        opacity: 0;
        transform: translate(0, 0);
    }
    3% {
        opacity: 0;
        transform: translate(10px, 0);
    }
    22% {
        opacity: 0;
        transform: translate(-68px, 0);
    }
    25% {
        opacity: 0;
        transform: translate(-58px, 0);
    }
    50% {
        opacity: 1;
        transform: translate(-58px, 0);
    }
    53% {
        opacity: 1;
        transform: translate(-68px, 0);
    }
    72% {
        opacity: 1;
        transform: translate(10px, 0);
    }
    75% {
        opacity: 0;
        transform: translate(0, 0);
    }
    100% {
        opacity: 0;
        transform: translate(0, 0);
    }
}

.notification-bar {
    z-index: 49;
    position: fixed;
    top: 0;
    bottom: -50px;
    left: 0;
    right: 0;

    overflow: scroll;

    transition: transform 0.5s ease-in-out,
                bottom 0.5s ease-in-out;

    &[data-shown='false'] {
        transform: translate(0, -110%);
        bottom: 0;
    }

    & .notification {
        transition: transform 0.3s ease-in-out,
                    margin 0.3s ease-in-out;
    }

    & .notification-list {
        overflow: auto;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }
}

.notification {
    transition: transform 0.3s ease-in-out,
                margin 0.3s ease-in-out;
}

.notification-list {
    overflow: auto;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
}

.skeleton {
    animation: skeleton-color 2s infinite;

    & > * {
        opacity: 0;
        cursor: default;
    }
}

@keyframes skeleton-color {
    0% {
        background-color: #EDECEC;
    }
    50% {
        background-color: #E0DFDF;
    }
    100% {
        background-color: #EDECEC;
    }
}

img.block-tail {
    position: absolute;
    left: 0;
    bottom: -17px;
    height: 43px;
}

.angle-moving {
    position: relative;
    animation: angle-moving 1.5s infinite;
}

@keyframes angle-moving {
    0% {
        transform: translate(0, 5px) rotate(90deg);
    }
    50% {
        transform: translate(0, -5px) rotate(90deg);
    }
    100% {
        transform: translate(0, 5px) rotate(90deg);
    }
}

.input-select-list {
    z-index: 3; 
    position: relative; 
    overflow: visible;
}

.no-pointer {
    pointer-events: none;
}

.inner-hover:not(:hover) > .child-hover {
    display: none;
}
