@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

$breakpoints: ("lg": 1920px, "md": 1280px, "sm": 1024px, "xs": 768px);

// @font-face {
//     font-family: Manrope;
//     src: url(../../../../public/font/Manrope-Thin.woff2) format(woff2),
//          url(../../../../public/font/Manrope-Thin.ttf) format(truetype);

//     font-weight: 100;
//     font-style: normal;
// }

// @font-face {
//     font-family: Manrope;
//     src: url(../../../../public/font/Manrope-ExtraLight.ttf) format(truetype);

//     font-weight: 200;
//     font-style: normal;
// }

// @font-face {
//     font-family: Manrope;
//     src: url(../../../../public/font/Manrope-Light.woff2) format(woff2),
//          url(../../../../public/font/Manrope-Light.ttf) format(truetype);

//     font-weight: 300;
//     font-style: normal;
// }

// @font-face {
//     font-family: Manrope;
//     src: url(../../../../public/font/Manrope-Regular.woff2) format(woff2),
//          url(../../../../public/font/Manrope-Regular.ttf) format(truetype);

//     font-weight: 400;
//     font-style: normal;
// }

// @font-face {
//     font-family: Manrope;
//     src: url(../../../../public/font/Manrope-Medium.woff2) format(woff2),
//          url(../../../../public/font/Manrope-Medium.ttf) format(truetype);

//     font-weight: 500;
//     font-style: normal;
// }

// @font-face {
//     font-family: Manrope;
//     src: url(../../../../public/font/Manrope-Semibold.woff2) format(woff2),
//          url(../../../../public/font/Manrope-SemiBold.ttf) format(truetype);

//     font-weight: 600;
//     font-style: normal;
// }

// @font-face {
//     font-family: Manrope;
//     src: url(../../../../public/font/Manrope-Bold.woff2) format(woff2),
//          url(../../../../public/font/Manrope-Bold.ttf) format(truetype);

//     font-weight: 700;
//     font-style: normal;
// }

// @font-face {
//     font-family: Manrope;
//     src: url(../../../../public/font/Manrope-ExtraBold.woff2) format(woff2),
//          url(../../../../public/font/Manrope-ExtraBold.ttf) format(truetype);

//     font-weight: 800;
//     font-style: normal;
// }